<template>
    <div>
        <template v-if="!items.length">
            <p class="subtitle-1 secondary--text py-5 text-center"><v-icon color="primary" class="mr-1">fa fa-info-circle</v-icon> You no recent activity logged.</p>
        </template>
        <template v-else>
                <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page="10"
                class="elevation-1"
                :expanded.sync="expanded"
                item-key="uuid"
                :single-expand="true"
                hide-default-header
                mobile-breakpoint="10"
            >
                <template v-slot:item="{ item }">
                    <tr>
                        <td class="lh-12 py-2" style="min-width: 185px; max-width: 200px;">
                            <span class="d-block text-capitalize subtitle-1 d-md-none mb-0">{{ $moment.utc(item.posted).tz(user.timezone).fromNow() }}</span>
                            <span class="d-none text-capitalize body-2 d-md-block font-weight-medium">{{ $moment.utc(item.posted).tz(user.timezone).fromNow() }}</span>
                            <span class="d-block font-weight-medium d-md-none mb-1"><v-icon small :color="getTimeColor(item.type)" class="pr-1">fa fa-sms</v-icon> {{ types[item.type] }}</span>
                            <span class="caption d-inline-block">{{ $moment.utc(item.created_at).tz(user.timezone).format('MMM Do YYYY [at] h:mma') }}</span>
                        </td>
                        <td class="hidden-sm-and-down" width="80%">
                            <v-icon :color="getTimeColor(item.type)" class="pr-1">fa fa-sms</v-icon>
                            {{ types[item.type] }}
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </template>
    </div>
</template>
<script>
    
import { mapState } from 'vuex'

export default {
    name: 'UserActivityActivity',
    props: {
        items: {
            required: true,
            type: Array
        }
    },
    components: {
    },
    computed: {
        ...mapState(['user']),
    },
    data: () => ({
        expanded: [],
        headers: [
          {
            text: 'Activity',
            align: 'left',
            sortable: true,
            value: 'created_at'
          },
          { text: 'Action', sortable: true, value: 'updated_at', align: 'left hidden-sm-and-down' }
        ],
        types: [
            '',
            'We sent you a check-in.',
            'You Replied to your Check-In.',
            '',
            'We sent you a warning after you missed your check-in. Your contacts will be alerted in 15 minutes.',
            'We sent an alert to your emergency contacts after missing your check-in.'
        ],
    }),
    methods: {
        initData(){
            
        },
        getTimeColor(type){
            if(type == 4){
                return 'warning';
            }
            if(type == 5){
                return 'error';
            }
            if(type == 2){
                return 'success';
            }
            return 'primary';
        },
    },
    mounted(){
		this.initData();
	},
    watch: {
        
    }
}
</script>